.App {
  text-align: center;
  font-family: Poppins;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Table__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: flex-start;
  padding: 20px 10px;
}

.Table__page {
  display: flex;
  justify-content: flex-end;
  padding: 25px 15px;
}

.Table__visiblePagesWrapper {
  margin-left: 10px;
  margin-right: 10px;
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  height: 35px;
  width: 35px;
  border-radius: 5px;
  background-color: transparent;
  color: #383e4a;
  cursor: pointer;
}

.Table__pageButton--active,
.Table__pageButton:hover {
  color: #fff;
  font-weight: bold;
  -webkit-transition: background-color 3.3s ease;
  background-color: #074785;
}

.switchPageButton {
  font-size: 15px;
  outline: none;
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  height: 35px;
  width: 35px;
  border-radius: 5px;
  background-color: #f0f9ff;
  color: #383e4a;
  cursor: pointer;
}

.switchPageButton:disabled {
  cursor: not-allowed;
  color: #e0e5f5;
  background-color: #fbfcff;
}

.switchPageButton:disabled:hover {
  cursor: not-allowed;
  color: #f1f4fc;
  font-weight: normal;
  background-color: #fbfcff;
}

.switchPageButton:active,
.switchPageButton:hover {
  color: #fff;
  background-color: #074785;
}

.select-page-text {
  color: #05223d;
  font-size: 13px;
  margin-left: 15px;
}

.select-page {
  border-width: 0;
  color: #383e4a;
  font-size: 13px;
  font-weight: 600;
  height: 35px;
  padding-left: 10px;
  width: 60px;
  border-radius: 5px;
  background: url(./assets/media/icons/chevron-down.svg) no-repeat right #f0f9ff;
  -webkit-appearance: none;
}

.select-page option {
  font-size: 13px;
  font-weight: bold;
}

.select-page:hover {
  border-width: 0;
  color: #fff;
  background-color: #074785;
}

.select-page:active {
  border-width: 0;
  color: #fff;
  background-color: #074785;
  -webkit-appearance: none;
}
